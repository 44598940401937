import {
  ArrayField,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  ListButton,
  RefreshButton,
  TopToolbar,
  BulkDeleteWithUndoButton,
  useRecordContext,
  SimpleForm,
  Labeled,
  DateInput,
  NumberInput,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { UserRole } from '../../variables/user-role';
import { cloneElement } from 'react';
import { UserLevel } from '../../variables/user-level';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { FormType } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { capitalize } from '../../utils/string';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

let userRoles = Object.values(UserRole).map((role) => ({ id: role, name: capitalize(role) }));
let userLevels = Object.values(UserLevel).map((level) => ({ id: level, name: capitalize(level) }));

const ListActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={total}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn={true} />
    <AutocompleteArrayInput source="role" choices={userRoles} alwaysOn={true} />
    <AutocompleteArrayInput source="level" choices={userLevels} alwaysOn={true} />
    <BooleanInput label="Verified" source="verified" />
  </Filter>
);

export const UserList = (props) => (
  <List
    {...props}
    sort={{ field: 'id', order: SortingDirection.ASC }}
    perPage={50}
    filters={<Filters />}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="fullName" />
      <TextField source="spaceshipsBalance" label="Spaceships" />
      <TextField source="claimedRewards" />
      <TextField source="role" />
      <TextField source="level" />
      <DateField source="createdAt" locales="ru" />
      <EditButton />
    </Datagrid>
  </List>
);

const UserTitle: any = () => {
  const record = useRecordContext();

  return (
    <span>
      User #{record?.id}: {record?.email}
    </span>
  );
};

const Form = (props) => {
  return (
    <SimpleForm toolbar={<DefaultToolBar />} {...props}>
      <TextField source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextField source="spaceshipsBalance" label="Spaceships" />
      <TextField source="rewards.length" label="Claimed Rewards" />
      <DateField source="createdAt" label="Date of registration" />
      <SelectInput source="role" choices={userRoles} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderTop: 1,
          borderBottom: 1,
          minWidth: 300,
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Subscription</Typography>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Labeled label="Level">
            <TextField source="ui_subscription_level" style={{ textTransform: 'capitalize' }} />
          </Labeled>
          <Labeled label="Start date">
            <DateField source="ui_subscription_start_at" emptyText="N/A" />
          </Labeled>
          <Labeled label="End date">
            <DateField source="ui_subscription_end_at" />
          </Labeled>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderBottom: 1,
          minWidth: 300,
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Affiliate Program</Typography>

        <BooleanInput source="affiliate_ban" defaultValue={false} />

        <Labeled label="Affiliae Code">
          <TextField source="affiliate_code" />
        </Labeled>
        <Labeled label="Joined At">
          <DateInput source="affiliate_joined_at" />
        </Labeled>
        <Labeled label="Affiliate User Id">
          <NumberInput source="affiliate_user_id" />
        </Labeled>
      </Box>

      <SelectInput source="level" choices={userLevels} />

      <TextField source="username" />
      <TextField source="walletAddress" />
      <TextField source="telegramBotStatus" />

      <br />

      <TextInput source="password" label="Set New Password" />
      <TextField label="Rewards Info:" />
      <>
        <ArrayField source="rewards">
          <Datagrid>
            <TextField source="title" label="Reward name" />
            <TextField source="data" label="Code" />
            <DateField source="dateOfReceive" label="Date of receive" />
            <FunctionField
              label="Link"
              render={(record) => {
                return <a href={`/#/write-offs/${record.id}`}>Link to {record.title}</a>;
              }}
            />
          </Datagrid>
        </ArrayField>
      </>
    </SimpleForm>
  );
};

const UserEditActions = ({ basePath }: any) => {
  return (
    <TopToolbar>
      <ListButton />
      <RefreshButton />
      <AdminDeleteButton />
    </TopToolbar>
  );
};

export const UserEdit = (props) => (
  <Edit actions={<UserEditActions />} title={<UserTitle />} {...props}>
    <Form type={FormType.EDIT} />
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <Form type={FormType.CREATE} />
  </Create>
);
