export enum AffiliateWithdrawRequestStatus {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending',
}

export const STATUS_CHOICES = [
  { id: AffiliateWithdrawRequestStatus.CANCELLED, name: 'Cancelled' },
  { id: AffiliateWithdrawRequestStatus.COMPLETED, name: 'Completed' },
  { id: AffiliateWithdrawRequestStatus.IN_PROGRESS, name: 'In Progress' },
  { id: AffiliateWithdrawRequestStatus.PENDING, name: 'Pending' },
];
